import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [2,4];

export const dictionary = {
		"/(auth)": [5,[2]],
		"/(auth)/(app)/(authenticated)/account": [6,[2,3]],
		"/account/(redirect-logged-in-users)/login": [25,[4]],
		"/account/(redirect-logged-in-users)/r4d6hfh-j123hd-72hgdj0-dhjkdhha23": [26,[4]],
		"/account/reset-password": [29],
		"/account/reset-password/confirm": [30],
		"/account/(redirect-logged-in-users)/signup": [27,[4]],
		"/account/verify-email": [31],
		"/account/(redirect-logged-in-users)/verify": [28,[4]],
		"/(auth)/(app)/(authenticated)/admin": [7,[2,3]],
		"/(auth)/(app)/(authenticated)/admin/student-availability": [8,[2,3]],
		"/(auth)/(app)/(authenticated)/admin/teacher-availability": [9,[2,3]],
		"/(auth)/(app)/(authenticated)/admin/teacher-calendar": [10,[2,3]],
		"/(auth)/(app)/(authenticated)/availability": [11,[2,3]],
		"/(auth)/(app)/(authenticated)/dashboard": [12,[2,3]],
		"/(auth)/(app)/(authenticated)/group-lessons": [13,[2,3]],
		"/(auth)/(app)/(authenticated)/home": [14,[2,3]],
		"/(auth)/(app)/(authenticated)/my-todo-lists": [15,[2,3]],
		"/(auth)/payment/success": [24,[2]],
		"/(auth)/(app)/(authenticated)/scheduling": [16,[2,3]],
		"/(auth)/(app)/(authenticated)/student-availability-old": [19,[2,3]],
		"/(auth)/(app)/(authenticated)/student-availability": [18,[2,3]],
		"/(auth)/(app)/(authenticated)/student": [17,[2,3]],
		"/(auth)/(app)/(authenticated)/subject": [20,[2,3]],
		"/(auth)/(app)/(authenticated)/teacher-availability": [22,[2,3]],
		"/(auth)/(app)/(authenticated)/teacher-calendar": [23,[2,3]],
		"/(auth)/(app)/(authenticated)/teacher": [21,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';